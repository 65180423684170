.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.power_on {
  background: #c0ffc0;
}

.big-btn {
  width: 200pt;
  height: 40pt;
  font-size: 16pt;
}

.price-button-text {
  text-transform: none;
  font-weight: 700;
}
